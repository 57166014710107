<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'DesktopNavigation',
  computed: {
    ...mapState('Navigation', ['menuNavigation']),
    ...mapGetters({
      userDetails: 'Session/getUserDetails',
    }),
  },
};
</script>

<template>
  <nav id="nav" class="main-nav">
    <ul class="main-nav__list">
      <template v-for="nav in menuNavigation">
        <li v-if="nav.showDesktop" :key="nav.text" class="main-nav__item" :class="nav.className">
          <a
            :data-analytics="`Main Menu : ${nav.text}`"
            :class="[{ 'main-nav__link--dropdown': nav.secondaryNavItems.length > 0 }]"
            :href="nav.url"
            class="main-nav__link"
          >
            <template v-if="nav.desktopIcon">
              <img :src="nav.desktopIcon" :alt="nav.text" class="main-nav__icon" />
            </template>

            <template v-else>
              {{ nav.text }}
            </template>

            <span v-if="nav.text === 'Melbourne Cup'" class="nav-badge nav-badge--pulsate">Featured</span>
          </a>

          <template v-if="nav.secondaryNavItems.length > 0">
            <ul class="secondary-nav">
              <template v-for="secondNav in nav.secondaryNavItems">
                <li v-if="secondNav.showDesktop" :key="secondNav.text">
                  <a
                    :data-analytics="`Secondary Menu : ${nav.text} : ${secondNav.text}`"
                    :href="secondNav.url"
                    class="secondary-nav__link"
                    :class="{
                      'secondary-nav__link--dropdown':
                        secondNav.secondaryNavItems && secondNav.secondaryNavItems.length > 0,
                    }"
                    >{{ secondNav.text }}</a
                  >
                  <template v-if="secondNav.secondaryNavItems && secondNav.secondaryNavItems.length > 0">
                    <ul class="third-nav">
                      <template v-for="thirdNav in secondNav.secondaryNavItems">
                        <li v-if="thirdNav.showDesktop" :key="thirdNav.text">
                          <a
                            :data-analytics="`Third Menu : ${nav.text} : ${secondNav.text} : ${thirdNav.text}`"
                            :href="thirdNav.url"
                            class="third-nav__link"
                          >
                            {{ thirdNav.text }}
                          </a>
                        </li>
                      </template>
                    </ul>
                  </template>
                </li>
              </template>
            </ul>
          </template>
        </li>
      </template>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
@import './styles/_DesktopNavigation';
</style>
