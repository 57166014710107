<script>
import { mapGetters, mapState } from 'vuex';
import DesktopNavigation from '../Navigation/DesktopNavigation';
// import AdBanner from 'babel-loader!@newsperform/core-component-lib/src/components/AdBanner/AdBanner.vue';
import HeaderDesktopRight from './HeaderDesktopRight.vue';
export default {
  name: 'HeaderDesktop',
  components: {
    // AdBanner,
    HeaderDesktopRight,
    DesktopNavigation,
  },
  props: {
    userDetails: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState('Subscriptions', ['isSubscriber']),
    ...mapGetters({
      isSearchActive: 'HeaderSearch/isSearchActive',
      privateMessages: 'PrivateMessages/getPrivateMessages',
    }),

    isMobile() {
      return window.innerWidth < 678;
    },
  },
  mounted() {
    if (!this.isMobile) {
      window.addEventListener('scroll', this.handleStickyHeader);
    }
  },
  destroyed() {
    if (!this.isMobile) {
      window.removeEventListener('scroll', this.handleStickyHeader);
    }
  },
  methods: {
    handleStickyHeader() {
      const mainHeader = this.$refs.headerFixed;
      const stickyTarget = document.getElementById('nav');
      const stickyTargetTop = stickyTarget.getBoundingClientRect().y;
      const wrapper = document.getElementById('wrapper');
      if (mainHeader.clientHeight >= stickyTargetTop) {
        mainHeader.classList.add('--sticky');
        wrapper.style.padding = '55px 0';
      } else {
        mainHeader.classList.remove('--sticky');
        wrapper.style.padding = '0';
      }
    },
  },
};
</script>

<template>
  <div class="header-navigation__container">
    <div ref="headerFixed" class="header__fixed">
      <div :class="{ '--search-active': isSearchActive }" class="header__container">
        <a href="/" class="header__logo">
          <img
            class="header__logo-img"
            :src="
              isSubscriber
                ? 'https://puntcdn.com/subscriptions/punters-plus-secondary.svg'
                : 'https://puntcdn.com/icons/punterslogodesktop.svg'
            "
            alt="PuntersLogo"
          />
        </a>
        <HeaderDesktopRight :user-details="userDetails" :private-messages="privateMessages" />
      </div>
    </div>
    <DesktopNavigation />
  </div>
</template>

<style lang="scss">
@import './styles/_HeaderDesktop';
</style>
