import dayjs from 'dayjs';
import Utc from 'dayjs/plugin/utc';
import { displayAdBanner } from 'babel-loader!@newsperform/core-component-lib/src/utils/adBanner';

dayjs.extend(Utc);

const loadTealium = function ({ sections, pageTitle, article = null, user, isSubscriber, $route, isMobile, $brand }) {
  window.utag_data = window.utag_data || {};
  const displayAd = displayAdBanner({ isSubscriber, $route, isMobile, $brand });
  if (!displayAd) {
    window.utag_data.disable_tad = true;
  }

  if (!window.utag) {
    // Disable 3rd party integrations for tests
    if (!process.env.TESTENV) {
      loadScript();
    }
    window.utag_data = getUtagData(sections, pageTitle, user, article, isSubscriber);
  }
};

const getUtagData = function (sections, pageTitle, userDetails, article, isSubscriber) {
  const viewportWidth = Math.max(window.document.documentElement.clientWidth, window.innerWidth || 0);
  const platform = viewportWidth > 760 ? 'web' : 'mobile';
  const currentPage = sections[0];
  const memberType = userDetails && userDetails.isLoggedIn ? 'registered' : 'anonymous';
  const activeUserId = userDetails && userDetails.isLoggedIn ? userDetails.userId : '';

  if (sections.length === 0) {
    sections[0] = 'home';
  }

  const utagData = {
    net_pn: pageTitle,
    net_platform: platform, // Use 'web' for desktop/tablet, or 'mobile' for mobile.
    net_site: 'punters',
    net_site_brand: 'punters',
    net_sec1: sections[0] ? `punters.com.au | ${sections[0]}` : '',
    net_sec2: sections[1] || '',
    net_sec3: sections[2] || '',
    net_content_type: currentPage === 'news' ? 'content+news' : 'content',
    net_site_uid: activeUserId,
    pc_memtype: memberType,
    ss_pun_bookmaker_account: '',
    net_article_date: article && article.datePublished ? dayjs(article.datePublished).format('YYYY-MM-DD') : '',
    net_article_byline: article?.author?.name || '',
    net_article_headline: article && article.title ? article.title : '',

    // AD TAGS
    ad_unit: 'punters',
    ad_sec1: sections[0] || '',
    ad_sec2: sections[1] || '',
    ad_sec3: sections[2] ? sections[2].split('-').join('') : '',
    ad_sec4: '',
    ad_sec5: '',
    ad_kw: article && article.tags ? article.tags.map(a => a.name).join(', ') : '',
    ad_aid: pageTitle,
    ad_pagetype: getPageType(sections),
  };

  return utagData;
};

const updateTealium = function (sections, pageTitle, userDetails, article, isSubscriber) {
  setTimeout(() => {
    // destroy bonzai truskin if it exists
    typeof window.bonzai !== 'undefined' && typeof window.bonzai.destroy !== 'undefined' && window.bonzai.destroy();
    // update tealium tags
    Object.assign(window.utag_data, getUtagData(sections, pageTitle, userDetails, article, isSubscriber));
    if (window.utag) window.utag.view();
    // reset optin monster
    if (window.om1074_22350) window.om1074_22350.reset();
  }, 100);
};

const loadScript = function () {
  const script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('defer', 'true');
  const urlPath = window.location.host;
  const isQA = urlPath.includes('localhost') || urlPath.includes('staging.');
  if (isQA) {
    script.setAttribute('src', '//tags.tiqcdn.com/utag/newsltd/punters/qa/utag.js');
  } else {
    script.setAttribute('src', '//tags.tiqcdn.com/utag/newsltd/punters/prod/utag.js');
  }
  document.body.appendChild(script);
};

const getPageType = function (sections) {
  const productPages = ['Form Guide Horse Racing', 'Results Horse Racing', 'form-guide', 'racing-results'];
  const currentPage = sections[0];
  let pageType = '';

  if (currentPage === 'home') {
    pageType = 'homepage';
  } else if (currentPage === 'news' && sections.length === 1) {
    pageType = 'index';
  } else if (currentPage === 'news' && sections.length > 1) {
    pageType = 'story';
  } else if (productPages.includes(currentPage)) {
    pageType = 'product';
  }

  return pageType;
};

export { loadTealium, updateTealium, getUtagData };
