<script>
import { mapState, mapGetters } from 'vuex';
import AdBanner from 'babel-loader!@newsperform/core-component-lib/src/components/AdBanner/AdBanner';
import HeaderDesktop from './HeaderDesktop.vue';
import HeaderMobile from './HeaderMobile.vue';
export default {
  name: 'Header',
  components: {
    HeaderDesktop,
    HeaderMobile,
    AdBanner,
  },
  props: {
    displayAppAd: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('Global', ['isMobile', 'isMobileApp']),
    ...mapGetters({
      userDetails: 'Session/getUserDetails',
      isMobileMenuActive: 'Global/isMobileMenuActive',
    }),
    raceType() {
      const raceType = this.$route.params.raceType;
      return raceType;
    },
    isWatchPage() {
      return this.$route.path === '/watch/';
    },
  },
  async mounted() {
    await this.getUserFromSession();
  },
  methods: {
    async getUserFromSession() {
      await this.fetchUserAndMessages();
    },
    async fetchUserAndMessages() {
      await this.$store.dispatch('Session/getUserFromSessionCookie');
      await this.$store.dispatch('PrivateMessages/getUserPrivateMessages');
      this.$auth.initialized = true;
      this.$auth.loading = false;
      const { userDetails } = this;
      if (userDetails.isLoggedIn) {
        this.$auth.authenticated = true;
        this.$auth.user = {
          id: userDetails.userId,
          username: userDetails.username,
          email: 'UNAVAILABLE',
        };
      }
    },
  },
};
</script>

<template>
  <div class="header-container">
    <div v-if="!isMobile" id="header-ad" class="header-ad">
      <AdBanner ad-type="ad-hybrid-leader-billboard" />
    </div>
    <HeaderDesktop :user-details="userDetails" />
    <HeaderMobile v-if="!isMobileApp" :user-details="userDetails" @toggle-menu="$e => $emit('handleToggleMenu', $e)" />
    <div
      v-if="displayAppAd || !isMobileApp"
      v-show="!isMobileMenuActive"
      id="header-ad-mobile"
      class="header-ad-mobile header-ad-mobile--sticky"
      :class="{ 'header-ad-mobile--display-app-ad': displayAppAd }"
    >
      <AdBanner v-if="isMobile" ad-type="ad-m-banner" />
    </div>
  </div>
</template>

<style lang="scss">
.header-ad-mobile {
  display: block;
  text-align: center;
  margin-top: 60px;

  &--sticky {
    margin-top: 0;
    padding-top: 10px;
    position: fixed;
    top: 50px;
    z-index: 5;
    text-align: center;
    width: 100%;
    background: $alpacas;
  }

  &--display-app-ad {
    top: 0;
  }

  @include for-tablet-landscape-up {
    display: none;
  }
}
.header-ad {
  display: none;

  @include for-tablet-landscape-up {
    display: block;
    .ad-block__container {
      min-height: 290px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

//desktop
.header-navigation__container {
  display: none;
  @include for-tablet-landscape-up {
    display: block;
  }
}
//mobile
#header-wrap {
  display: block;
  @include for-tablet-landscape-up {
    display: none;
  }
}
</style>
