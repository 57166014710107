<script>
import { createNamespacedHelpers } from 'vuex';
import { ANALYTICS_EVENT_LINK } from 'babel-loader!@newsperform/core-component-lib/src/utils/nameLibrary';

const { mapState } = createNamespacedHelpers('HeaderSearch');

export default {
  name: 'HeaderDesktopSearchResults',
  computed: {
    ...mapState({
      searchResultsList: state => state.searchResults,
    }),
  },
  methods: {
    analyticsProps(title) {
      const { $getAnalyticsProps } = this;
      return $getAnalyticsProps(`Desktop Header : Search : ${title}`, ANALYTICS_EVENT_LINK);
    },
  },
};
</script>

<template>
  <div class="header-search-results__container">
    <div class="search__autocomplete-container">
      <template v-for="results in searchResultsList">
        <div :key="`${results.group}`" :class="`search__autocomplete search__autocomplete--${results.group}`">
          <div class="search__autocomplete-group-header">
            {{ results.group }}
          </div>
          <template v-for="item in results.items">
            <div :key="item.id" :class="`search__autocomplete-item search__autocomplete--${item.type}`">
              <a :href="`${item.pageUrl}`" v-bind="analyticsProps(item.title)">
                <div class="autocomplete-item__title">{{ item.title }}</div>
                <div class="autocomplete-item__description">
                  {{ item.description }}
                </div>
              </a>
            </div>
          </template>
        </div>
      </template>
    </div>
    <div class="autocomplete__see-all-gradient"></div>
  </div>
</template>

<style lang="scss" scoped>
@import './styles/_HeaderDesktopSearchResults';
</style>
