<script>
export default {
  mounted() {
    // set tealium to SPA mode after initial page load
    // https://docs.tealium.com/platforms/javascript/single-page-applications/
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
        window.utag_cfg_ovrd.noview = true;
      }
    };
  },
};
</script>
