<script>
import { mapGetters, mapState } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import { ANALYTICS_EVENT_MENU } from 'babel-loader!@newsperform/core-component-lib/src/utils/nameLibrary';
import PrivateMessageList from '../PrivateMessages/PrivateMessageList.vue';
import HeaderDesktopSearch from './HeaderDesktopSearch.vue';
export default {
  name: 'HeaderDesktopRight',
  components: {
    HeaderDesktopSearch,
    PrivateMessageList,
  },
  mixins: [clickaway],
  props: {
    userDetails: {
      type: Object,
      default: () => {},
    },
    privateMessages: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapState('Subscriptions', ['isSubscriber', 'isLoggedIn']),
    ...mapGetters({
      messageModalActive: 'PrivateMessages/isMessageModalActive',
    }),
    newMessageCount() {
      return this.privateMessages.hasUnread || 0;
    },
    subscribeReturnUrl() {
      return `${process.env.BASE_URL}${this.$route.path}`;
    },
  },
  methods: {
    toggleMessageModal() {
      const { messageModalActive } = this;
      this.$store.commit('PrivateMessages/SET_USER_MESSAGE_MODAL_ACTIVE', !messageModalActive);
    },
    closeModal() {
      this.$store.commit('PrivateMessages/SET_USER_MESSAGE_MODAL_ACTIVE', false);
    },
    analyticsProps(title) {
      const { $getAnalyticsProps } = this;
      return $getAnalyticsProps(`Desktop Header : ${title}`, ANALYTICS_EVENT_MENU);
    },
  },
};
</script>

<template>
  <div class="header__right">
    <HeaderDesktopSearch />
    <div class="header__user-menu">
      <a
        v-if="!isSubscriber && isLoggedIn"
        rel="nofollow"
        class="header__btn header__btn--subscribe header__btn--subscribe-login"
        :href="`/subscribe/news/1/?sourceCode=PUWEB_ONS539&amp;dest=${subscribeReturnUrl}`"
        v-bind="analyticsProps('Subscribe')"
        >Subscribe</a
      >
      <a class="header__blackbook" href="/blackbook/" v-bind="analyticsProps('Blackbook')">
        <span class="header__blackbook-text">Black Book</span>
      </a>

      <template v-if="!isLoggedIn">
        <a class="header__btn login-web" href="/login/" rel="nofollow" v-bind="analyticsProps('Login')">Log in</a>
        <a
          rel="nofollow"
          class="header__btn header__btn--subscribe"
          :href="`/subscribe/news/1/?sourceCode=PUWEB_ONS539&amp;dest=${subscribeReturnUrl}`"
          v-bind="analyticsProps('Subscribe')"
          >Subscribe</a
        >
      </template>
      <template v-else>
        <a
          class="pm-list__show-messages"
          href="javascript:void(-1);"
          v-bind="analyticsProps('Show Message')"
          @click="toggleMessageModal"
        >
          <span v-if="newMessageCount > 0" class="pm-list__message-count">{{ newMessageCount }}</span></a
        >
        <PrivateMessageList v-if="messageModalActive" v-on-clickaway="closeModal" :private-messages="privateMessages" />
        <div class="header__account-info">
          <a class="header__balance" href="/account-settings/balance/" v-bind="analyticsProps('Balance')"
            >${{ userDetails.accountBalance | twoDecimalPlaces }}</a
          >
          <div class="header__user-actions-show">
            <a
              :href="`/profiles/${userDetails.username}/`"
              class="header__display-user-actions"
              v-bind="analyticsProps('Profile')"
            >
              <img :src="userDetails.profileImageUrl" class="header__avatar" />
            </a>
            <ul class="header__user-actions">
              <li>
                <a
                  :href="`/profiles/${userDetails.username}/`"
                  class="header__action-link analyticsevent"
                  data-analytics-category="UserMenu"
                  data-analytics-action="click"
                  data-analytics-label="Edit Profile Web"
                  v-bind="analyticsProps('Profile : Punters Profile')"
                  >Profile</a
                >
              </li>
              <li>
                <a
                  class="header__action-link analyticsevent"
                  href="/tips/enter-tips/"
                  data-analytics-category="UserMenu"
                  data-analytics-action="click"
                  data-analytics-label="Enter Tips Web"
                  v-bind="analyticsProps('Profile : Enter Tips')"
                  >Enter Tips</a
                >
              </li>
              <li>
                <a
                  class="header__action-link analyticsevent"
                  href="/tips/purchased/"
                  data-analytics-category="UserMenu"
                  data-analytics-action="click"
                  data-analytics-label="Purchased Tips Web"
                  v-bind="analyticsProps('Profile : Purchased Tips')"
                  >Purchased Tips</a
                >
              </li>
              <li>
                <a
                  class="header__action-link"
                  href="/account-settings/balance/"
                  v-bind="analyticsProps('Profile : My Balance')"
                  >My Balance (${{ userDetails.accountBalance | twoDecimalPlaces }})</a
                >
              </li>
              <li class="divider"></li>
              <li>
                <a
                  class="header__action-link"
                  href="/logout/"
                  rel="nofollow"
                  v-bind="analyticsProps('Profile : Logout')"
                  >Logout</a
                >
              </li>
            </ul>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
@import './styles/_HeaderDesktopRight';
</style>
