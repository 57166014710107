<script>
// libs
import { mapState, mapActions, mapMutations } from 'vuex';
import { ANALYTICS_EVENT_MENU } from 'babel-loader!@newsperform/core-component-lib/src/utils/nameLibrary';

// components
import HeaderMobileSearchResults from './HeaderMobileSearchResults.vue';

export default {
  name: 'HeaderMobile',
  components: {
    HeaderMobileSearchResults,
  },
  props: {
    userDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      siteSearch: '',
    };
  },
  computed: {
    ...mapState('HeaderSearch', ['searchResults']),
    ...mapState('Subscriptions', ['isSubscriber']),
    subscribeReturnUrl() {
      return `${process.env.BASE_URL}${this.$route.path}`;
    },
  },
  watch: {
    siteSearch: {
      immediate: true,
      handler(query) {
        this.handleSearchChange(query);
      },
    },
  },

  methods: {
    ...mapActions('HeaderSearch', ['fetchSearch']),
    ...mapMutations('HeaderSearch', ['setActive', 'resetSearchResults']),
    handleSearchChange(query) {
      if (query.length >= 2) {
        this.fetchSearch(query);
      } else {
        this.resetSearchResults();
      }
    },
    toggleSearch(isActive) {
      if (!isActive) {
        this.siteSearch = '';
        this.resetSearchResults();
      }
      this.setActive(isActive);
    },

    analyticsProps(title) {
      const { $getAnalyticsProps } = this;
      return $getAnalyticsProps(`Desktop Header : ${title}`, ANALYTICS_EVENT_MENU);
    },
  },
};
</script>

<template>
  <div id="header-wrap">
    <header id="top" class="colourHeader">
      <div id="header" class="signin-container">
        <div class="mobile-menu clearfix">
          <div class="punters-mobile-logo-sizer">
            <a href="/" class="punters-mobile-brand">
              <img
                class="punters-mobile-logo"
                alt="punters-logo"
                :src="
                  isSubscriber
                    ? 'https://puntcdn.com/subscriptions/punters-plus-tertiary.svg'
                    : 'https://puntcdn.com/icons/punters-mobile-header.svg'
                "
              />
            </a>
          </div>
          <div class="mobile-menu__right-container">
            <!-- LOGGED OUT -->
            <div v-if="!userDetails.isLoggedIn" class="mobile-menu__right-container__unauth-container">
              <a
                href="#"
                class="mobile-header__button mobile-header__button--multiple-buttons"
                title="Login"
                v-bind="analyticsProps('Login')"
              >
                Log in
              </a>
              <a
                rel="nofollow"
                class="mobile-header__button"
                :href="`/subscribe/news/1/?sourceCode=PUWEB_MOB539&amp;dest=${subscribeReturnUrl}`"
                v-bind="analyticsProps('Subscribe')"
              >
                Subscribe
              </a>
            </div>
            <!-- LOGGED IN -->
            <div v-else class="mobile-avatar-area">
              <a
                v-if="!isSubscriber"
                rel="nofollow"
                class="mobile-header__button"
                :href="`/subscribe/news/1/?sourceCode=PUWEB_MOB539&amp;dest=${subscribeReturnUrl}`"
                v-bind="analyticsProps('Subscribe')"
              >
                Subscribe
              </a>

              <a class="blackbook-icon" href="/blackbook/" v-bind="analyticsProps('Blackbook')">
                <img src="https://puntcdn.com/icons/blackbook/blackbook-white.svg" />
              </a>

              <a :href="`/profiles/${userDetails.username}/`" class="user-avatar" v-bind="analyticsProps('Profile')">
                <!-- TODO: MESSAGES -->
                <template v-if="false">
                  <div class="user-avatar__pm-count">
                    {{ privateMessageCount }}
                  </div>
                </template>
                <img :src="userDetails.profileImageUrl" :alt="userDetails.username" class="user-avatar__img" />
              </a>
            </div>
            <!-- SHORTLIST -->
            <a class="shortlist-menu" href="/shortlist/" v-bind="analyticsProps('Shortlist')">
              <div class="shortlist-star"></div>
            </a>
          </div>
        </div>
        <!-- Mobile search on navigation pop up -->
        <div id="mobile-search-container">
          <form method="GET" action="/search/" class="siteSearch" rel="search">
            <label for="txtSearch" hidden>Search for horses, jockeys, trainers, and more</label>
            <input
              id="txtSearch"
              v-model="siteSearch"
              type="text"
              size="24"
              name="q"
              class="mobile-search-text"
              autocomplete="off"
              placeholder="Search for horses, jockeys, trainers, and more"
            />
          </form>

          <HeaderMobileSearchResults v-if="searchResults.length > 0" />
        </div>
      </div>
    </header>
  </div>
</template>

<style lang="scss">
@import './styles/_HeaderMobile';
</style>
