<script>
import { createNamespacedHelpers } from 'vuex';
import { ANALYTICS_EVENT_MENU } from 'babel-loader!@newsperform/core-component-lib/src/utils/nameLibrary';
import HeaderDesktopSearchResults from './HeaderDesktopSearchResults.vue';
const { mapState, mapActions, mapMutations } = createNamespacedHelpers('HeaderSearch');
export default {
  name: 'HeaderDesktopSearch',
  components: {
    HeaderDesktopSearchResults,
  },
  data() {
    return {
      siteSearch: '',
    };
  },
  computed: {
    ...mapState({
      isSearchActive: state => state.isActive,
      searchResultsList: state => state.searchResults,
    }),
  },
  watch: {
    siteSearch: {
      immediate: true,
      handler(query) {
        this.handleSearchChange(query);
      },
    },
  },

  methods: {
    ...mapActions({
      fetchSearch: 'fetchSearch',
    }),
    ...mapMutations({
      setActive: 'setActive',
      resetSearchResults: 'resetSearchResults',
    }),
    handleSearchChange(query) {
      if (query.length >= 2) {
        this.fetchSearch(query);
      } else {
        this.resetSearchResults();
      }
    },
    toggleSearch(isActive) {
      if (!isActive) {
        this.siteSearch = '';
        this.resetSearchResults();
      }
      this.setActive(isActive);
    },
    analyticsProps(title) {
      const { $getAnalyticsProps } = this;
      return $getAnalyticsProps(`Desktop Header : ${title}`, ANALYTICS_EVENT_MENU);
    },
  },
};
</script>

<template>
  <form method="GET" action="/search/" class="header__search-form siteSearch" rel="search">
    <a
      id="headerSearchSubmit"
      class="header__search-icon"
      href="#"
      v-bind="analyticsProps('Search')"
      @click.prevent="toggleSearch(true)"
    >
      <label for="txtSearchHeader" hidden>Search for horses, jockeys, trainers, and more</label>
    </a>
    <input
      id="txtSearchHeader"
      v-model="siteSearch"
      type="text"
      size="24"
      name="q"
      class="header__search-input search-text"
      autocomplete="off"
      placeholder="Enter Keyword"
    />

    <HeaderDesktopSearchResults v-if="isSearchActive && searchResultsList.length > 0" />

    <a class="header__search-close" href="#" @click.prevent="toggleSearch(false)"></a>
  </form>
</template>

<style lang="scss">
@import './styles/_HeaderDesktopSearch';
</style>
