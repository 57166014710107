<script>
export default {
  name: 'PrivateMessageList',
  props: {
    privateMessages: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    async toggleMessageThreadModal(otherUserId) {
      await this.$store.dispatch('PrivateMessages/togglePrivateMessageModal', { otherUserId });
      await this.$store.commit('PrivateMessages/SET_USER_MESSAGE_MODAL_ACTIVE', false);
    },
  },
};
</script>

<template>
  <div>
    <div class="private-message-list">
      <div :class="{ 'pm-list__message-container--visible': true }" class="pm-list__message-container">
        <div class="pm-list__heading">
          Messages <span class="pm-list__unread">{{ privateMessages.unread }} New</span>
        </div>
        <div class="pm-list__messages">
          <template v-if="!privateMessages.hasMessages">
            <div class="pm-list__empty">
              <div class="pm-list__empty-text">You don't have any messages yet.</div>
              <div class="pm-list__empty-text--small">
                Start a private message by searching for a user or browsing to their profile page.
              </div>
            </div>
          </template>
          <template v-else>
            <a
              v-for="message in privateMessages.messages"
              :key="message.sendTimestamp"
              :class="{ 'pm-list__message--unread': message.isUnread === 1 }"
              :data-uid="message.userId"
              class="pm-list__message display__pm-modal"
              href="javascript:void(-1);"
              @click="toggleMessageThreadModal(message.userId)"
            >
              <img :src="message.avatar" :alt="message.username" class="pm-list__avatar" />
              <div class="pm-list__message-inner">
                <div class="pm-list__username">
                  {{ message.username }}
                  <abbr :data-utime="message.sendTimestamp" class="pm-list__timestamp timestamp timeSince"
                    >{{ message.sendTimestamp | puntersTimestamp }}
                  </abbr>
                </div>
                <div class="pm-list__recent-message">{{ message.recentMessage }}</div>
              </div>
            </a>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import './styles/_PrivateMessageList';
</style>
